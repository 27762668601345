*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
 
}
.main{
  background-image: url('https://4kwallpapers.com/images/wallpapers/assassins-creed-valhalla-vikings-gameplay-2020-games-2880x1800-600.jpg');
  background-size: cover;
  height: 100vh;
}
.head{
  justify-content: center;
  align-items: center;
}
.imgs{
 margin-left: 1000px;
 margin-top: 80px;
 border: 3px solid #ffffff;
}
.btn{
  background: rgba(255, 217, 0, 0.696);
  margin-top: 100px;
  font-family: 'Kablammo', cursive;
  font-size: 1.3rem;
  border: 3px solid #ffffff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.btn:hover{
  background: rgba(255, 255, 255, 0.319);
  color: rgb(0, 0, 0);
  border: 3px solid #000000;
  box-shadow: 4px 4px 4.5px rgba(255, 255, 255, 0.564);
}
.card-grid{
  margin-top: 40px;
  display: grid;
  grid-template-columns:   1fr 1fr 1fr 1fr 1fr 1fr ;

}
.front{
  width: 150px;
  height: 170px;
 
}
.back{
  width: 150px;
  height: 170px;
 
}
.card{
  width: 170px;
  height: 0%;
 margin-left: 50px;
 margin-bottom: 30px;
 margin-right: 0px;
}
.turns{
  text-align: center;
  font-family: 'Yeseva One', cursive;
  color: rgb(255, 255, 255);
  font-size: 25px;
  background: rgba(255, 255, 255, 0.319);
  margin-top: 40px;
  text-shadow: 2px 2px 8px #000000;
}
.hturns{
  display: flex;
  justify-content: center;
  align-items: center;
}



.sign {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  background-image: radial-gradient(
    ellipse 50% 35% at 50% 50%,
    #6b1839,
    transparent
  );
  transform: translate(-50%, -50%);
  letter-spacing: 2;
  left: 50%;
  top: 7%;
  font-family: "Clip";
  text-transform: uppercase;
  font-size: 3em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  animation: shine 2s forwards, flicker 3s infinite;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}